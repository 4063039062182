const messages = {
  // steps
  step1: "الخطوة 1",
  step2: "الخطوة 2",
  step3: "الخطوة 3",

  // products website and mobile apps
  products: "المنتجات",
  "products.description":
    "أفضل برمجيات التجارة الإلكترونية في العالم للعلامات التجارية والتجار. توصيل خلال ساعات بأقل تكلفة باستخدام شبكتك التجزئة الحالية",
  "products.eazy": "سمارتستور إيزي",
  "products.enterprise": "المتجر الذكي المؤسسي",
  "products.productfinder.heading1": "متجر كبير",
  "products.productfinder.heading2": "محدد المنتجات الذكي",
  "products.productfinder.description":
    "هل تمتلك متجر بقالة كبير أو متجر إداري؟ ساعد عملائك على التنقل في متجرك والعثور على ما يبحثون عنه بسهولة.",
  "products.zoppay.heading1": "بوابة الدفع",
  "products.zoppay.heading2": "بوابة الدفع الذكية",
  "products.zoppay.description":
    "دمج خطوة واحدة لقبول المدفوعات عبر الإنترنت من البوابات التي تختارها.",
  "products.intellipos.heading1": "متجر مستدام ذاتي الاستمرار",
  "products.intellipos.heading2": "نقطة البيع الذكية",
  "products.intellipos.description":
    "نظام نقاط البيع الذكي الذي يسمح للمستخدم والمشغل بالتشغيل واستخدام النظام بشكل متزامن مع معلومات مكملة على الشاشتين المزدوجتين.",
  "products.groceryexpress.heading1": "جهاز الإنترنت من الأشياء",
  "products.groceryexpress.heading2": "غروسري اكسبريس",
  "products.groceryexpress.description":
    "أحد أنواعه. جهاز طلب IOT متعدد الوحدات الذي يتصل بحساب ZopNow ويسمح للمستخدم بالطلب من باب الثلاجة أو أي سطح آخر على هذا النحو.",

  // products website and mobile apps
  "products.websiteandmobileapps.title": "موقع الويب والتطبيقات المحمولة",
  "products.websiteandmobileapps.websiteService.item1":
    "يمكن توصيل حلنا الأمامي عبر أجهزة الكمبيوتر الشخصية والهواتف المحمولة (Android و iOS)، في غضون أسابيع، لتوفير تجربة موحدة للعملاء.",
  "products.websiteandmobileapps.websiteService.item2":
    'مع "سلة في السحابة"، يمكن لجميع أفراد الأسرة إضافة إلى "سلة الأسرة" من أجهزتهم الخاصة في أي وقت،',
  "products.websiteandmobileapps.languageservice.description":
    "السماح بسهولة للعملاء بالتبديل بين اللغات (دون التأثير على نظام ERP الخاص بك). مثالي للبائعين الذين يخدمون مجتمعات مختلفة (محلية وأجانب).",

  // products marketing
  "products.marketing.title": "التسويق",
  "products.marketing.personalisation.heading": "التخصيص",
  "products.marketing.personalisation.item1":
    'يتم عرض نتائج فريدة، استنادًا إلى نشاط الشراء الفردي، لكل من "البحث" و "الاستبدال".',
  "products.marketing.personalisation.item2":
    'إذا طلب العميل منتجًا كان لديه مشاكل في التنفيذ مؤخرًا، فإن نظامنا يقترح بشكل نشط بدائل "شخصية" مؤكدة مسبقًا كبديل مفضل.',
  "products.marketing.recommendation.heading": "التوصية",
  "products.marketing.recommendation.description":
    "تستفيد خوارزمياتنا الخاصة من مجموعة متنوعة من المتغيرات بما في ذلك تاريخ التسوق، والتكرار، والحدث الأخير وما إلى ذلك للتأثير بشكل متقن على سلوك المستهلكين استنادًا إلى آلاف المستهلكين المماثلين. تم تطوير خوارزمياتنا من قبل نفس الفريق الذي يدير محركات التخصيص والتوصية في أمازون. يمكن دمج هذه المحركات مع حل الأمامي الحالي الخاص بك.",
  "products.marketing.promotion.heading": "حملات الترويج",
  "products.marketing.promotion.description":
    "نقسم قاعدة العملاء في الوقت الحقيقي، استنادًا إلى عدة معلمات تجمع بين أفعال واحتياجات المستهلك مع موقعهم لتحديد القسائم / العروض الأكثر وعدًا.",
  "products.marketing.loyalty.heading": "الولاء",
  "products.marketing.loyalty.description":
    "يمكن توصيلها عبر أماكن متعددة كجزء من برنامج ولاء يديره ZopSmart أو دمجها مع المسؤول الحالي الخاص بك.",

  // products opertation and management page
  "products.operationsmanagement.title": " إدارة العمليات",
  "products.operationsmanagement.heading": "تخصيص وتتبع الطلبات",
  "products.operationsmanagement.item1":
    "تخصيص الطلبات عبر الإنترنت تلقائيًا للمتاجر وأفراد العمل الفردية.",
  "products.operationsmanagement.item2":
    "تتبع الحالة الحية لجميع الطلبات الفعالة في الوقت الحقيقي.",
  "products.operationsmanagement.planning.heading": "التخطيط",
  "products.operationsmanagement.planning.description":
    "تطبيق جوال يعرض لفرد الموظف الطريقة المثلى لاختيار العناصر مع موازنة الوقت ومتطلبات التعبئة.",
  "products.operationsmanagement.picking.heading":
    "الاختيار والمسح في الوقت الفعلي",
  "products.operationsmanagement.picking.description":
    "يقوم فرد الموظف بالاختيار والمسح في الوقت الفعلي:",
  "products.operationsmanagement.picking.item1":
    "تعلم من الأخطاء في الاختيار التي تُعلم ف",
  "products.operationsmanagement.picking.item2":
    "الإيصال يتم بناؤه أثناء الطريق، يتم توجيه فرد الموظف بالاستبدالات، مما يلغي الحاجة إلى مكالمات العملاء.",
  "products.operationsmanagement.packing.heading": "لتعبئة",
  "products.operationsmanagement.packing.description":
    "يتلقى فرد الموظف تعليمات واضحة حول كيفية التعبئة (أي العناصر في أي صندوق) ومكان التخزين.",
  "products.operationsmanagement.selfupdatedesc.heading":
    "تحديثات الرف في الوقت الفعلي",
  "products.operationsmanagement.selfupdatedesc.item1":
    "أثناء اختيار الموظفين من الرفوف، يمكن تصعيد أي عناصر نفاد المخزون في الوقت الفعلي عبر تطبيقهم المحمول.",
  "products.operationsmanagement.selfupdatedesc.item2":
    "إذا كان المخزون في الخلف، يمكن ملء الرفوف في وقت أكثر توازنًا.",

  // products scan and go page
  "products.scanandgo.title": "أمسح وأنطلق",
  "products.scanandgo.scansteps.heading": "سهل الاستخدام",
  "products.scanandgo.scansteps.step1":
    "يقوم العميل بتشغيل التطبيق و(التمكين من GPS ”التسجيل الداخلي“)،",
  "products.scanandgo.scansteps.step2":
    "العميل يمسح ويضع العناصر على هاتفه أثناء تنقله.",
  "products.scanandgo.scansteps.step3":
    "العميل يدفع داخل التطبيق ويتحقق من الإيصال الإلكتروني.",

  // products logistic page
  "products.logistics.title": "اللوجستيات",
  "products.logistics.booking.description": "الحجز",
  "products.logistics.booking.item1": "يختار العميل الفتحة المفضلة.",
  "products.logistics.booking.item2":
    "الحوافز المقدمة للتأثير على اختيار فترات الوقت.",
  "products.logistics.booking.item3": "توفير نافذة تسليم للعميل لمدة 30 دقيقة.",
  "products.logistics.planning.description": "التخطيط",
  "products.logistics.planning.item1": "تجميع ديناميكي للطلبات.",
  "products.logistics.planning.item2": "استهلاك 100% من الطاقة المتاحة كهدف.",
  "products.logistics.planning.item3": "اقتراح مسار لأقصر وقت للعودة.",
  "products.logistics.delivery.description": "تحسين التسليم",
  "products.logistics.delivery.item1": "تعيين تلقائي للمركبة وسائق التوصيل.",
  "products.logistics.delivery.item2": "يعرض الخريطة المتكاملة المسار.",
  "products.logistics.delivery.item3": "إرجاع المنتجات في المكان.",
  "products.logistics.delivery.item4": "حساب محكم للمرتجعات.",
  "products.logistics.tracking.description": "تتبع",
  "products.logistics.tracking.item1":
    "مراقبة المسار للعميل والمدير على حد سواء.",
  "products.logistics.tracking.item2": "إرسال تنبيهات للعميل بشكل نشط.",

  // products click and collect page
  "products.collectandgo.title": "أطلب و استلم ",
  "products.collectandgo.booking.description": "الحجز",
  "products.collectandgo.booking.item1": "يقوم العميل بتقديم الطلب.",
  "products.collectandgo.booking.item2": "تحديد وقت الاستلام.",
  "products.collectandgo.booking.item3": "خيار إضافة الطلب إلى الخرائط.",
  "products.collectandgo.trackingandnotification.description": "تتبع وإشعارات",
  "products.collectandgo.trackingandnotification.item1":
    "يمكن للعميل إدارة الطلب وتتبع حالته من خلال تطبيق.",
  "products.collectandgo.trackingandnotification.item2":
    "تلقي إشعار بمجرد جاهزية الطلب.",
  "products.collectandgo.drivetostore.description": "القيادة إلى المتجر",
  "products.collectandgo.drivetostore.item1":
    "استخدام تطبيق الخرائط للتنقل إلى المتجر.",
  "products.collectandgo.drivetostore.item2":
    "مشاركة وقت الوصول مع المتجر أثناء السفر (تحديث في الوقت الحقيقي).",
  "products.collectandgo.collectgoods.description": "استلام البضائع",
  "products.collectandgo.collectgoods.item1": "تأكيد الوصول عبر التطبيق.",
  "products.collectandgo.collectgoods.item2": "تأكيد نقطة الاستلام.",
  "products.collectandgo.collectgoods.item3": "يتلقى العميل التسليم.",

  // products analytics
  "products.analytics.title": "التحليلات",
  "products.analytics.newproduct.heading": "منتج جديد",
  "products.analytics.newproduct.description1":
    "استخدم تحليلات البيانات لتحديد الفرص لزيادة المبيعات من خلال المنتجات الجديدة / مجموعات المنتجات.",
  "products.analytics.newproduct.description2":
    "على سبيل المثال: إذا كانت مبيعات منتج معين منخفضة في متجر معين، فاستكشاف ما إذا كانت المجتمعات (بالقرب من المتجر) على الارجح ستتفاعل مع علامة تجارية مختلفة.",
  "products.analytics.workforcemanagement.heading": "إدارة القوى العاملة",
  "products.analytics.workforcemanagement.description1":
    "تتبع مؤشرات الأداء الرئيسية الخاصة بالدور على مستوى الموظفين والمتجر لتقديم فرص التدريب والحوافز.",
  "products.analytics.workforcemanagement.description2":
    "على سبيل المثال: وقت اختيار معين لوحدة البيع; عدد المنتجات التالفة بواسطة سائق التوصيل; الفاقد في المتجر.",
  "products.analytics.skueconomics.heading": "اقتصاديات مستوى وحدة البيع (SKU)",
  "products.analytics.skueconomics.description1":
    "رؤى حول جميع عوامل التكلفة لوحدة بيع معينة لتحديد استراتيجية التسعير.",
  "products.analytics.skueconomics.description2":
    "على سبيل المثال: من خلال فهم بعض المنتجات التي تتطلب المزيد من الوقت للاختيار، قد نقرر زيادة السعر لتعويض ذلك.",

  // products customerSupport
  "products.customersupport.title": "دعم العملاء",
  "products.customersupport.selfhelp.heading":
    "تمكين العملاء من الخدمة الذاتية:",
  "products.customersupport.selfhelp.item1": "تغيير الطلبات / أوقات التسليم",
  "products.customersupport.selfhelp.item2": "الإبلاغ عن مشاكل مع الطلب",
  "products.customersupport.selfhelp.item3": "تغيير تفاصيل الاتصال",
  "products.customersupport.selfhelp.item4": "التحقق من الحالة",
  "products.customersupport.supportstaff.heading":
    "تدعمها أدوات قوية لموظفي الدعم:",
  "products.customersupport.workflowmanagement.heading":
    "إدارة سير العمل لموظفي مركز الاتصال",
  "products.customersupport.workflowmanagement.item1":
    "توجيه المكالمات / البريد الإلكتروني تلقائيًا إلى فرق محددة",
  "products.customersupport.workflowmanagement.item2":
    "إعادة ربط العملاء بنفس الموظف لتوفير الاستمرارية",
  "products.customersupport.workflowmanagement.item3":
    "الوصول الفوري إلى تفاصيل العميل",
  "products.customersupport.disputemanagement.heading": "إدارة النزاعات",
  "products.customersupport.disputemanagement.description":
    "نظام إدارة النزاعات القائم على القواعد، يوفر مسارات حلول مختلفة لفئات العملاء المختلفة (على سبيل المثال، عملاء الذهب مقابل عملاء الفضة).",
  "products.customersupport.notifications.heading": "الإشعارات",
  "products.customersupport.notifications.description":
    'القدرة على إخطار مجموعات فرعية من العملاء (على سبيل المثال، "التأخير في التسليم بسبب تعطل السيارة" يتم إرسالها إلى جميع العملاء المتأثرين).',

  // products footer
  "products.footer.heading": "هل تحتاج إلى مزيد من المعلومات؟",
  "products.footer.description":
    "فقط اترك تفاصيل اتصالك أدناه وسيتصل بك خبير التجارة الإلكترونية لدينا في أقرب وقت ممكن.",
  "products.footer.fullname": "الاسم الكامل",
  "products.footer.mobilenumber": "رقم الهاتف المحمول",
  "products.footer.emailaddress": "البريد الإلكتروني",
  "products.footer.message": "رسالة",
  "products.footer.tellmemore": "أخبرني أكثر",

  // services
  services: "الخدمات",
  "services.acceleratingdevelopmentcycles": "تسريع دورات التطوير",
  "services.microservicesapplicationenvironment": "بيئة تطبيقات الميكروخدمات",
  "services.deliverypartnerintegration": "تكامل شريك التسليم",
  "services.processscaling": "توسيع العمليات",
  "services.cloudmigration": "ترحيل السحابة",

  //services  accelrating development cycles
  "services.acceleratingdevelopmentcycles.title": "تسريع دورات التطوير:",
  "services.acceleratingdevelopmentcycles.devdesc.description1":
    "كان كل فريق تطوير لدى عملائنا مسؤولًا عن تنفيذ المكونات الأساسية وفقًا للإرشادات.",
  "services.acceleratingdevelopmentcycles.devdesc.description2":
    "المشكلات الملحوظة التي نشأت هي عدم تبني متسق للإرشادات، والتغييرات التي تحتاج إلى تكرار المكونات عبر جميع الأنظمة.",
  "services.acceleratingdevelopmentcycles.devdesc.description3":
    "على مدى 6 - 8 أسابيع، نفذت ZopSmart إطارًا مركزيًا ومكتبة تُدعى حسب الحاجة.",
  "services.acceleratingdevelopmentcycles.steps.step1": "فريق التطوير 1",
  "services.acceleratingdevelopmentcycles.steps.step2": "فريق التطوير 2",
  "services.acceleratingdevelopmentcycles.steps.step3": "فريق التطوير 3",

  //services mcroservices application environment
  "services.microservicesapplicationenvironment.title":
    "بيئة تطبيقات الميكروخدمات",
  "services.microservicesapplicationenvironment.servicesegment.description1":
    "أراد عميلنا تبسيط كيفية إعادة استخدام الكود داخل الأنظمة القائمة بالفعل وكذلك تقليل النطاق المحتمل للمخاطر الناشئة عن تغييرات الكود.",
  "services.microservicesapplicationenvironment.servicesegment.description2":
    "على مدى حوالي 6 أشهر، قامت ZopSmart بإعادة هيكلة الأنظمة التقليدية وتحويل الأنظمة التقليدية الضخمة إلى سلسلة من الخدمات الصغيرة المستدعاة.",
  "services.microservicesapplicationenvironment.servicesegment.description3":
    "نتيجة لذلك، رأى العميل تقليلًا في أوقات التطوير الجديدة تصل إلى 30٪.",
  "services.microservicesapplicationenvironment.monolithic":
    "أنظمة الإرث الضخمة",
  "services.microservicesapplicationenvironment.microservices":
    "الخدمات الصغيرة",

  // services delivery partner integration
  "services.deliverypartnerintegration.title": "تكامل شريك التسليم",
  "services.deliverypartnerintegration.description1":
    "كان لدى عميلنا تنفيذ التكاملات مع شركاء التسليم (إنستاكارت، شيبت إلخ) على حالة بحالة، مما أدى إلى تكامل غير فعال وتدفق معلومات غير متسق (على سبيل المثال، تكرار تحديثات المخزون / الأسعار تختلف حسب الشريك).",
  "services.deliverypartnerintegration.description2":
    'قامت ZopSmart بإنشاء "طبقة التكامل الشريك" المشتركة (تم توفيرها في أقل من 3 أشهر) وهاجرت جميع الشركاء القائمين بالفعل إلى الإطار الجديد.',
  "services.deliverypartnerintegration.header.title":
    "التكامل مع الشريك حسب الحاجة",
  "services.deliverypartnerintegration.header.title2": "طبقة التكامل الشريك",

  // services cloud migration
  "services.cloudmigration.title": "تحول السحابة",
  "services.cloudmigration.description1":
    "عميلنا في رحلة متعددة السنوات لنقل جميع الأنظمة من المواقع إلى السحابة حيث يسعى إلى تقليل التكاليف وتسريع دورات التطوير من خلال:",
  "services.cloudmigration.description.item1": "سعة تخزين مرنة",
  "services.cloudmigration.description.item2": "سعة حسابية مرنة",
  "services.cloudmigration.description.item3":
    "الوصول السهل إلى مجموعة واسعة من أدوات التطوير والأمان الأصلية للسحابة",
  "services.cloudmigration.description2":
    "بفهمنا العميق لكل من التقنية السحابية وأنظمة التجزئة التكنولوجية، تم اختيار ZopSmart للشراكة مع فريق التطوير الداخلي في التخطيط وتنفيذ مشاريع الهجرة.",
  "services.cloudmigration.trustedpartner.heading": "شريك موثوق ل",
  "services.cloudmigration.trustedpartner.description1":
    "تحديد وترتيب متطلبات الهجرة",
  "services.cloudmigration.trustedpartner.description2":
    "اختيار بيئة السحابة المستهدفة",
  "services.cloudmigration.trustedpartner.description3": "تخطيط الهجرة",
  "services.cloudmigration.trustedpartner.description4":
    "تنفيذ الهجرة وصيانة بيئة السحابة",

  // privacy policy
  "privacy.policy.p1":
    "يعتبر احترام خصوصيتك أمرًا مهمًا بالنسبة لنا في Zopsmart، ويعد سياسة Zopsmart احترام خصوصيتك والامتثال لأي قانون أو تنظيم قابل للتطبيق فيما يتعلق بأي معلومات شخصية قد نجمعها عنك، بما في ذلك عبر موقعنا على الويب، ومواقع أخرى نمتلكها ونديرها.",
  "privacy.policy.p2":
    "تعتبر هذه السياسة سارية المفعول اعتبارًا من 25 يونيو 2021 وتم تحديثها لآخر مرة في 25 يونيو 2021.",
  "info.we.collect": "المعلومات التي نجمعها",
  "info.we.collect.p1":
    "المعلومات التي نجمعها تشمل المعلومات التي تقدمها بنفسك عن علم وبنشاط لنا عند استخدام أو المشاركة في أي من خدماتنا وعروضنا، وأي معلومات يتم إرسالها تلقائيًا من أجهزتك أثناء الوصول إلى منتجاتنا وخدماتنا.",
  "log.data": "بيانات السجل",
  "log.data.p1":
    "عند زيارتك لموقعنا على الويب، قد تسجل خوادمنا تلقائيًا البيانات القياسية التي تقدمها متصفح الويب الخاص بك. قد تشمل عنوان بروتوكول الإنترنت (IP) لجهازك، ونوع وإصدار متصفحك، والصفحات التي تزورها، وتاريخ ووقت زيارتك، والوقت الذي قضيته في كل صفحة، وتفاصيل أخرى حول زيارتك، والتفاصيل التقنية التي تحدث بالتزامن مع أي أخطاء قد تواجهها.",
  "log.data.p2":
    "يرجى أن تكون على علم بأنه في حين أن هذه المعلومات قد لا تكون معرفة بطريقة شخصية بمفردها، فقد يكون من الممكن دمجها مع بيانات أخرى لتحديد هوية الأشخاص الفردية.",
  "personal.info": "المعلومات الشخصية",
  "personal.info.p1": "قد نطلب معلومات شخصية قد تشمل واحدة أو أكثر من ما يلي:",
  "personal.info.p1.li1": "الاسم",
  "personal.info.p1.li2": "البريد الإلكتروني",
  "personal.info.p1.li3": "ملفات الوسائط الاجتماعية",
  "reasons.for.processing.personal.info":
    "أسباب مشروعة لمعالجة معلوماتك الشخصية",
  "reasons.for.processing.personal.info.p1":
    "نقوم بجمع واستخدام معلوماتك الشخصية فقط عندما نكون لدينا سبب مشروع للقيام بذلك. في هذه الحالة، نقوم بجمع معلومات شخصية لا تزيد على ما هو ضروري بشكل معقول لتقديم خدماتنا لك.",
  "collection.use.info": "جمع واستخدام المعلومات",
  "collection.use.info.p1":
    "قد نقوم بجمع معلومات شخصية منك عندما تقوم بأي من الإجراءات التالية على موقعنا على الويب:",
  "collection.use.info.p1.li1":
    "استخدام جهاز محمول أو متصفح ويب للوصول إلى محتوانا.",
  "collection.use.info.p1.li2":
    "الاتصال بنا عبر البريد الإلكتروني، وسائل التواصل الاجتماعي، أو أي تقنيات مماثلة.",
  "collection.use.info.p1.li3": "عندما تذكرنا على وسائل التواصل الاجتماعي.",
  "collection.use.info.p2":
    "نقوم بجمع واحتجاز واستخدام وكشف المعلومات لأغراض الاتصال التالية، ولن يتم معالجة المعلومات الشخصية بشكل أكبر من ذلك وفقًا لهذه الأغراض:",
  "collection.use.info.p2.li1":
    "لتمكينك من الوصول إلى موقعنا على الويب وتطبيقاته المرتبطة ومنصات وسائل التواصل الاجتماعي المرتبطة.",
  "collection.use.info.p3":
    "يرجى ملاحظة أنه قد نقوم بدمج المعلومات التي نجمعها عنك مع معلومات عامة أو بيانات بحثية نتلقاها من مصادر أخرى موثوقة.",
  "security.your.personal.info": "أمان معلوماتك الشخصية:",
  "security.your.personal.info.p1":
    "عند جمع ومعالجة المعلومات الشخصية، وأثناء الاحتفاظ بها، سنحميها بالوسائل المقبولة تجاريًا لمنع الفقدان والسرقة، وكذلك الوصول غير المصرح به، والكشف، والنسخ، والاستخدام، أو التعديل.",
  "security.your.personal.info.p2":
    "على الرغم من أننا سنبذل قصارى جهدنا لحماية المعلومات الشخصية التي تقدمها لنا، إلا أننا ننصح بأنه لا يمكن ضمان أمان البيانات بنسبة 100% عبر الإرسال الإلكتروني أو التخزين الإلكتروني، ولا يمكن لأحد أن يضمن الأمان الكامل للبيانات. سنلتزم بالقوانين القابلة للتطبيق علينا فيما يتعلق بأي انتهاك للبيانات.",
  "security.your.personal.info.p3":
    "أنت مسؤول عن اختيار أي كلمة مرور وقوة أمانها العامة، وضمان أمان معلوماتك الخاصة ضمن حدود خدماتنا.",
  "how.log.keep.personal.info": "مدة الاحتفاظ بمعلوماتك الشخصية:",
  "how.log.keep.personal.info.p1":
    "نحتفظ بمعلوماتك الشخصية فقط لما نحتاج إليه. قد تعتمد هذه الفترة على الأغراض التي نستخدم فيها معلوماتك، وفقًا لسياسة الخصوصية هذه. إذا لم تعد معلوماتك الشخصية مطلوبة، فسنقوم بحذفها أو جعله",
  "how.log.keep.personal.info.p2":
    "ومع ذلك، في حال الضرورة، قد نحتفظ بمعلوماتك الشخصية للامتثال لالتزام قانوني أو محاسبي أو تقريري، أو لأغراض الأرشفة في مصلحة عامة، أو لأغراض البحث العلمي أو التاريخي، أو لأغراض الإحصائيات.",
  "children.privacy": "خصوصية الأطفال:",
  "children.privacy.p1":
    "لا نستهدف أي من منتجاتنا أو خدماتنا مباشرة للأطفال دون سن الثالثة عشرة، ولا نقوم بجمع معلومات شخصية عن الأطفال دون سن الثالثة عشرة بشكل معرف، ونعمل على ضمان حماية خصوصية الأطفال بما يتوافق مع القوانين ذات الصلة.",
  "disclose.personal.info.to.third.party":
    "كشف المعلومات الشخصية لأطراف ثالثة:",
  "disclose.personal.info.to.third.party.p1":
    "قد نكشف المعلومات الشخصية للأطراف الثالثة، وذلك على سبيل المثال لا الحصر:",
  "disclose.personal.info.to.third.party.p1.li1":
    "الشركات الأم، والشركات التابعة، أو الشركات التابعة لنا.",
  "disclose.personal.info.to.third.party.p1.li2":
    "مقدمي الخدمات الطرفية لأغراض تمكينهم من تقديم خدماتهم، مثل مقدمي خدمات تكنولوجيا المعلومات، وتخزين البيانات، واستضافة المواقع وخوادمها، والمعلنين، أو منصات التحليلات.",
  "disclose.personal.info.to.third.party.p1.li3":
    "موظفينا، ومقاولينا، و/أو الجهات أو الكيانات ذات الصلة.",
  "disclose.personal.info.to.third.party.p1.li4":
    "وكلاءنا الحاليين أو المحتملين أو شركاء الأعمال.",
  "disclose.personal.info.to.third.party.p1.li5":
    "رعات أو مروجي أي مسابقة أو جائزة، أو ترويج نقوم به،",
  "disclose.personal.info.to.third.party.p1.li6":
    "المحاكم والمحاكم والسلطات التنظيمية وضباط إنفاذ القانون، حسب الاقتضاء من القانون، فيما يتعلق بأي إجراءات قانونية فعلية أو محتملة، أو لتأسيس أو ممارسة أو الدفاع عن حقوقنا القانونية،",
  "disclose.personal.info.to.third.party.p1.li7":
    "الأطراف الثالثة، بما في ذلك الوكلاء أو المقاولين الفرعيين، الذين يساعدوننا في تقديم المعلومات أو المنتجات أو الخدمات أو التسويق المباشر لك، الأطراف الثالثة لجمع ومعالجة البيانات،",
  "international.transfer.personal.info":
    "التحويلات الدولية للمعلومات الشخصية،",
  "international.transfer.personal.info.p1":
    "تُخزن المعلومات الشخصية التي نجمعها و/أو نعالجها في الأماكن التي نحن أو شركاؤنا أو شركاتنا التابعة ومقدمي الخدمات الطرف الثالث يحتفظون بها أو يعالجونها فيها. يرجى أن تكون على علم بأن المواقع التي نخزن أو نعالج أو ننقل فيها معلوماتك الشخصية قد لا تكون لديها نفس قوانين حماية البيانات مثل البلد الذي قدمت فيه المعلومات في البداية. إذا قمنا بنقل معلوماتك الشخصية إلى أطراف ثالثة في بلدان أخرى: (i) سنقوم بتنفيذ تلك التحويلات وفقًا لمتطلبات القانون المعمول به؛ و (ii) سنحمي المعلومات الشخصية المحولة وفقًا لهذه السياسة الخاصة بالخصوصية.",
  "right.controlling.personal.info": "حقوقك والتحكم في معلوماتك الشخصية",
  "right.controlling.personal.info.p1":
    "دائمًا ما تحتفظ بالحق في عدم تقديم المعلومات الشخصية لنا، مع فهمك أن تجربتك على موقعنا قد تتأثر. لن نميز ضدك لممارسة أي من حقوقك فيما يتعلق بمعلوماتك الشخصية. إذا قمت بتقديم معلومات شخصية لنا، فإنك تفهم أننا سوف نجمعها ونحتفظ بها ونستخدمها ونكشف عنها وفقًا لهذه السياسة الخاصة بالخصوصية. تحتفظ بالحق في طلب تفاصيل أي معلومات شخصية نحتفظ بها عنك.",
  "right.controlling.personal.info.p2":
    "إذا تلقينا معلومات شخصية عنك من طرف ثالث، سنحميها كما هو محدد في هذه السياسة الخاصة بالخصوصية. إذا كنت طرفًا ثالثًا تقدم معلومات شخصية عن شخص آخر، فإنك تمثل وتضمن أنك تحصلت على موافقة هذا الشخص لتقديم المعلومات الشخصية لنا.",
  "right.controlling.personal.info.p3":
    "إذا وافقت سابقًا على استخدام معلوماتك الشخصية لأغراض التسويق المباشر من قبلنا، يمكنك تغيير رأيك في أي وقت. سنوفر لك القدرة على إلغاء الاشتراك من قاعدة بيانات البريد الإلكتروني الخاصة بنا أو الانسحاب من التواصل. يرجى أن تكون على علم أنه قد نحتاج إلى طلب معلومات محددة منك لمساعدتنا في تأكيد هويتك.",
  "right.controlling.personal.info.p4":
    "إذا كنت تعتقد أن أي معلومات نحتفظ بها عنك غير دقيقة أو قديمة أو غير كاملة أو غير ذات صلة أو مضللة، يرجى الاتصال بنا باستخدام التفاصيل المقدمة في سياسة الخصوصية هذه. سنتخذ خطوات معقولة لتصحيح أي معلومات تبين أنها غير دقيقة أو غير كاملة أو مضللة أو قديمة.",
  "right.controlling.personal.info.p5":
    "إذا كنت تعتقد أننا قد خرقنا أحد قوانين حماية البيانات ذات الصلة وترغب في تقديم شكوى، يرجى الاتصال بنا باستخدام التفاصيل أدناه وتقديم لنا كافة التفاصيل حول الانتهاك المزعوم. سنقوم بالتحقيق في شكواك بسرعة ونرد عليك كتابيًا، موضحين نتائج تحقيقنا والخطوات التي سنتخذها للتعامل مع شكواك. لديك أيضًا الحق في الاتصال بهيئة تنظيمية أو سلطة حماية البيانات المعنية فيما يتعلق بشكواك.",
  "use.of.cookies": "استخدام ملفات تعريف الارتباط",
  "use.of.cookies.p1":
    'نحن نستخدم "ملفات تعريف الارتباط" لجمع معلومات حولك ونشاطك عبر موقعنا. ملف تعريف الارتباط هو قطعة صغيرة من البيانات التي يخزنها موقعنا على جهاز الكمبيوتر الخاص بك، ويصل إليها في كل مرة تزور فيها، حتى نتمكن من فهم كيفية استخدامك لموقعنا. يساعدنا ذلك في تقديم محتوى يستند إلى التفضيلات التي حددتها.',
  "limits.of.policy": "حدود سياستنا",
  "limits.of.policy.p1":
    "موقعنا على الويب قد يحتوي على روابط لمواقع خارجية لا تديرها نحن. يرجى الإشارة إلى أننا لا نمتلك أي سيطرة على محتوى وسياسات تلك المواقع، ولا يمكننا قبول المسؤولية عن ممارسات الخصوصية الخاصة بها.",
  "changes.in.policy": "تغييرات في هذه السياسة",
  "changes.in.policy.p1":
    "بتقديرنا، قد نقوم بتغيير سياسة الخصوصية لتعكس التحديثات في عمليات أعمالنا، والممارسات المقبولة حاليًا، أو التغييرات التشريعية أو التنظيمية. إذا قررنا تغيير سياسة الخصوصية هذه، سنقوم بنشر التغييرات هنا على نفس الرابط الذي تستخدمه للوصول إلى هذه السياسة.",
  "changes.in.policy.p2":
    "إذا كانت هناك حاجة وفقًا للقانون، سنحصل على موافقتك أو نمنحك الفرصة للاختيار بالموافقة على أو عدم الموافقة على، حسب الاقتضاء، أي استخدام جديد لمعلوماتك الشخصية.",
  "contact.us.p1":
    "لأية أسئلة أو مخاوف بخصوص خصوصيتك، يمكنك التواصل معنا باستخدام التفاصيل التالية:",
  "zs.legal.team": "فريق Zopsmart القانوني",
  "legal.mail": "legal@zopsmart.com",
  //HOME > nav bar
  "nav.home": "الصفحة الرئيسية",
  "nav.products": "المنتجات",
  "products.website.mobile": "موقع الويب وتطبيقات الجوال",
  "products.marketing": "التسويق",
  "products.operations": "إدارة العمليات",
  "products.scan.go": "أمسح وأنطلق",
  "products.logistic": "اللوجستيات",
  "products.click.collect": "أطلب وأستلم",
  "products.analytics": "التحليلات",
  "products.customer.support": "دعم العملاء",
  "nav.technology": "التكنولوجيا",
  "nav.about.us": "من نحن",
  "nav.careers": "التوظيف",
  "nav.contact.us": "اتصل بنا",
  "select.language.heading": "اختار اللغة",
  //HOME > Banners
  "banner.1.text": "مرحبا بكم في البنية التحتية للتجزئة في الغد ",
  "banner.2.text":
    "توفير تغطية شاملة لاحتياجاتك متعددة القنوات من خلال حل معياري يمكنه البناء على ما لديك",
  // HOME > Hero Section
  "hero.zopsmart.technology": "تكنولوجيا زوب سمارت",
  "hero.zopsmart.technology.content":
    "زوب سمارت يساعد الشركات غير المتصلة على الانتقال عبر الإنترنت عن طريق توفير الأدوات والتقنيات اللازمة لإنشاء متجرهم عبر الإنترنت. هدفنا هو خلق قيمة وتقديم منتجات من البداية إلى النهاية لعملائنا المتعددين القنوات. نعرض للشركات كيف يمكنها التفاعل بشكل أفضل مع عملائها، والابتكار ضمن ميزانيات متوقعة من خلال الذهاب عبر الإنترنت مع منتجاتها في أسرع وقت ممكن. باستخدام ممارسات التصميم التي تركز على الإنسان، نساعد العملاء على إعادة تصوير أعمالهم، وتفاعل المستهلك، والتحليلات المثبتة، مع زمن سريع للسوق.",
  //HOME > Industries we specialize in
  "home.industries.specialised": "الصناعات التي نتخصص فيها",
  "industy.retail": "التجزئة",
  "industy.ecommerce": "التجارة الإلكترونية",
  "industy.logistics": "اللوجستيات",
  "industy.supply.chain": "سلسلة التوريد",
  "brands.helped": "العلامات التجارية التي ساعدنا في تشكيل أعمالها ومستقبلها",
  // HOME > Our Expertise
  "our.expert": "خبرتنا",
  "yr.of.exp": "سنوات من الخبرة",
  "developing.refining.retail": "تطوير وتحسين قدرات التجزئة",
  "digital.products.created": "منتج رقمي تم إنشاؤه لعملائنا",
  "engineers.engaged.retail": "مهندس مشارك في مشاريع التجزئة",
  "strong.cleints":
    "زبائن قويين يتضمنون عمالقة التجزئة ومنتجات CPG العالمية الأعلى،",
  "dedicated.UI/UX": "فريق متخصص في التصميم للتركيز على التصميم",
  "cloud.consulting.partners":
    "شراكات استشارية سحابية مع AWS، Google Cloud، Microsoft وAzure",
  //HOME > Footer
  "footer.whitepaper.download.error":
    "غير قادر على فتح المستند. يرجى التحقق من إعدادات متصفحك.,",
  "footer.download.whitepaper": " حمل الملف التقني ",
  "footer.company": "الشركة",
  "footer.about.us": "من نحن",
  "footer.contact.us": "اتصل بنا",
  "footer.products": "المنتجات",
  "footer.our.team": "فريقنا",
  "footer.privacy.policy": "سياسة الخصوصية",
  "footer.stay.in.touch": "ابق على اتصال",
  "footer.facebook": "فيسبوك",
  "footer.linkedIn": "لينكد إن",
  "footer.twitter": "تويتر",
  "footer.raramuri": "راراموري تكنولوجيز برايفت ليمتد،",
  "footer.rights": "جميع الحقوق محفوظة",

  // Technology
  "zopSmart.technology": "تقنية زوب سمارت  ",
  "technology.Description": "تقنية حديثة تناسب عملك،",
  "what.we.offer": "ما نقدمه  ",
  "what.we.offer.description":
    "نحن نستخدم إحدى أكثر تقنيات الويب المتقدمة لمنصة التجارة الإلكترونية. لقد بنينا المنصة باستخدام ReactJS و NextJS و GoLang و NodeJS و Java وقواعد بيانات SQL و NoSQL ومجموعة Redis Cluster للتخزين المؤقت ومجموعة Solr Cluster للبحث وقاعدة بيانات Neo4j Graph للتخصيص. نستضيف منصتنا على خدمات السحابة Google و Amazon و Azure التي تضمن أكثر من 99.99٪ من وقت التشغيل وإمكانية الوصول إلى التطبيق من أي مكان وفي أي وقت وعلى أي جهاز.    ",
  // Technology > Mainstays of our Technology
  "mainstays.technology": "محاور تقنيتنا  ",
  proven: "مجرب  ",
  "proven.description":
    "تقنيتنا في العمل منذ 7 سنوات، تتعامل مع آلاف المعاملات يوميًا. عملاؤنا يشملون كبار التجار في آسيا، بما في ذلك اثنين من أكبر متاجر البقالة الإلكترونية في الهند.    ",

  "low.cost": "تكلفة منخفضة  ",
  "low.cost.description":
    "معنا، تحصل على تقنية من الفئة الأولى بتكلفة معقولة. توفر الرأسمالية الكبيرة بالإضافة إلى التكاليف المتغيرة مع عدم وجود متطلبات للخادم، التطبيق والشخصية داخل المنزل.    ",
  "zero.time.to.market": "صفر وقت السوق  ",
  "zero.time.to.market.description":
    "معنا، يمكنك الوصول إلى السوق في وقت شبه صفري، حيث أن لدينا تقريبًا جميع قدرات التجارة الإلكترونية مبنية ومتوفرة جاهزة للاستخدام.    ",
  "flexible.&.scalable": "قابلة للتعديل والتوسعة  ",
  "flexible.&.scalable.description":
    "منصتنا مثالية للشركات التي تواجه طلبات غير متوقعة أو متقلبة. إذا زادت احتياجاتك، سنقوم تلقائيًا بتوسيع قدرة الخادم الخاص بك والعكس بالعكس.    ",
  manageable: "سهلة الإدارة  ",
  "manageable.description":
    "توفر منصتنا إمكانيات إدارة وصيانة تقنية المعلومات المحسنة والمبسطة من خلال الإدارة المركزية للموارد والبنية التحتية المُدارة وخدمات الدعم المضمونة بمستوى الخدمة.    ",
  secure: "آمنة  ",
  "secure.description":
    "تتم تشفير كل بياناتك وتخزينها في سحابة Google أو Amazon التي تتمتع بأكثر من 99.99٪ من وقت التشغيل. تحصل على أمان البيانات الكامل حتى في حالات الفشل الكهربائي الشديد أو الكوارث الطبيعية.    ",
  "manage.on.the.go": "إدارة أثناء التنقل  ",
  "manage.on.the.go.description":
    "مع منصتنا، إذا كان لديك هاتف ذكي واتصال بالإنترنت يمكنك العمل. يمكن الوصول بسهولة إلى واجهة الخلفية لدينا عبر المتصفح على أي جهاز.    ",
  "automatic.updates": "التحديثات التلقائية  ",
  "automatic.updates.description":
    "نحن نعتني بتطوير ونشر التحديثات البرمجية العادية بما في ذلك التحديثات الأمنية. لذلك لا داعي للقلق بشأن الحفاظ على تطبيقاتك وخوادمك.    ",
  modular: "مرنة  ",
  "modular.description":
    "برمجياتنا حقًا مرنة. لذا إذا كان لديك حل تجارة إلكترونية موجود بالفعل وترغب في إضافة بعض الوحدات فقط من حلنا فإننا نلبي احتياجاتك تمامًا.    ",

  "building.blocks.our.technology": "أركان تقنيتنا  ",

  // About Us
  "about.us.description":
    "نتصور أن نكون أول منصة تكنولوجيا تجزئة في العالم.    ",
  "our.experties.description":
    "في المجال التجاري يتم بناء تقنيتنا على تجربة فريقنا الهائلة في مجال سلع التجزئة والتجارة وسلسلة التوريد واللوجستيات. كل تجربتنا قد تم تطبيعها في عمليات الأعمال المدمجة في منتجاتنا.    ",
  "our.solution": "الحل لدينا  ",
  "our.solution.description":
    "تم بناء منتجنا على مدى 7 سنوات وهو يتعامل مع الآلاف من المعاملات كل يوم. تعتبر مجموعتنا من المنتجات واحدة من أكثر الحلول تقدمًا واستقرارًا وقابلية للتوسيع المتوفرة في عالم تقنيات التجزئة.    ",
  "our.team": "فريقنا",
  "our.team.description":
    "يتكون من متخصصي التجزئة المتحمسين الذين تخرجوا من أرقى المؤسسات التعليمية في العالم مثل IIT و IIM و MIT و ISB و Wharton.    ",
  mukesh: "موكيش سينغ",
  "mukesh.designation": "المؤسس",
  "mukesh.description":
    "حاصل على درجة البكالوريوس في علوم الحاسوب من جامعة IIT في كانبور. بعد تخرجه، سعى للحصول على درجة الدكتوراه في MIT، لكنه انسحب لبدء شركة تشغيل الرسوم المتحركة - Zoesis في بوسطن. في وقت لاحق عمل لدى eGain وAmazon وMakeMyTrip قبل أن يبدأ في تطوير منصة SmartStore للتجارة الإلكترونية.    ",
  raj: "راج باندي",
  "raj.designation": "الرئيس التنفيذي  ",
  "raj.description":
    "حاصل على درجة البكالوريوس في الهندسة من جامعة IIT في كانبور و MBA من كلية وارتون للأعمال. بعد عقد من التفاوض في وول ستريت كمصرف استثماري، عاد راج إلى الهند ليأخذ منصة SmartStore للتجارة الإلكترونية إلى الأسواق العالمية.    ",
  vikash: "فيكاش كومار",
  "vikash.designation": "المدير التقني الرئيسي  ",
  "vikash.description":
    "حاصل على درجة البكالوريوس في الهندسة من جامعة IIT في بومباي وهو واحد من القلائل الذين يتقنون التصوير والشعر على حد سواء. في المكتب نشير إليه بـ bond.    ",
  sanket: "سانكيت ميهتا",
  "sanket.designation": "رئيس العمليات  ",
  "sanket.description":
    "حاصل على درجة البكالوريوس في الهندسة من جامعة IIT في بومباي. ملك العمليات، يمكنك رؤيته في معظم الأوقات وهو يدير الأمور بهدوء وسكون.    ",
  rohit: "روهيت ساكسينا",
  "rohit.designation": "رئيس - حلول الأعمال  ",
  "rohit.description":
    "حاصل على MBA من مدرسة الأعمال الهندية في حيدر أباد. لقد عمل في مجال الاستشارات والتخطيط والتحليلات مع منظمات مثل ديل وتي في إس لوجيستيكس وويبرو.    ",

  // career
  "work.with.us": "اعمل معنا.  ",
  "work.with.us.description":
    "نحن شركة مقرها بنغالور. نحن جاهزون لتوظيف المواهب الجديدة للانضمام إلينا.    ",
  "about.working.here": "حول العمل هنا  ",
  "about.working.here.description": "تبرز من الحشد بموهبتك  ",
  "conducive.environment": "بيئة مواتية  ",
  "conducive.environment.description":
    "استمتع بالعمل التحدي والوظيفة الرضية دون أي تسلسل هرمي،    ",
  "best.gadgets": "أفضل الأجهزة  ",
  "best.gadgets.description":
    "استمتع بنفسك وأنت تدلل الخبير التقني بالأجهزة والتكنولوجيا المتقدمة،    ",
  "medical.insurance": "التأمين الطبي  ",
  "medical.insurance.description":
    "لدينا تأمين لك ولعائلتك حرفيًا، مع أفضل التغطية في فئتها،    ",
  "snacks.drinks": "الوجبات الخفيفة الصحية والمشروبات  ",
  "snacks.drinks.description":
    "الطعام متوفر في المنزل، حتى تشعر بأنك في منزلك،",
  "gilly's": "@ إعادة تعريف جيلي  ",
  "@PLaY.Arena": "@ بلاي أرينا",
  "@Koramangala.Social": "@كورامانجالا الاجتماعية",
  "@MACAW.By.Stories": "@الببغاء عن طريق القصص",
  "team.lunch": "غداء الفريق  ",
  "team.outing": "رحلة الفريق  ",
  area83: "@المنطقة 83  ",
  "mango.mist": "@مانجوميست  ",
  "join.zopsmart": "ترغب في الانضمام إلى فريق ZopSmart  ",
  "join.zopsmart.description":
    "تكنولوجيا رائعة. زملاء مذهلين. اكتشف عالم الابتكار والتعلم والنمو معنا،    ",
  "no.jobs": "لا توجد فرص عمل في الوقت الحالي  ",
  // apply: "قدم الآن  ",

  // contact us
  "contact.us.description":
    "خبراء التكنولوجيا التجارية لدينا متوفرين على مدار الساعة،    ",
  "write.us": "اكتب لنا  ",
  "write.us.description":
    "يرجى التواصل إذا كنت بحاجة إلى أي مساعدة في حلولنا أو تحتاج إلى معرفة أي شيء آخر في مجال التكنولوجيا التجارية،    ",
  "submit.form.error":
    "عذرًا! فشل إرسال النموذج. يرجى إرسال استفساراتك عبر البريد الإلكتروني إلى،    ",
  submitting: "التقديم",
  submit: "إرسال  ",
  "thank.you": "شكرًا لك  ",
  "will.get.in.touch": "سنتواصل معك قريبًا  ",
  address: "العنوان  ",
  "zs.address":
    "2078، الطريق الرئيسي 24، فانغاناهالي، القطاع 1، تخطيط HSR، بنغالور، كارناتاكا 560102،    ",
  mobile: "الهاتف المحمول  ",
  "email.id": "البريد الإلكتروني  ",

  // name: "الاسم  ",
  // "upload.resume": "تحميل السيرة الذاتية  ",
  // "drag.upload.resume": "اسحب أو قم بتحميل سيرتك الذاتية  ",
  // "current.location": "الموقع الحالي  ",
  // "notice.period": "فترة الإشعار  ",
  // "current.salary": "الراتب الحالي  ",
  // "in.lpa": "بالسنوي  ",
  // "expected.salary": "الراتب المتوقع  ",
  // "additional.info": "معلومات إضافية  ",
  // "cance;": "إلغاء  ",
  // save: "حفظ  ",
  // "enter.location": "أدخل موقعك الحالي  ",
  // "enter.linkedin.profile": "أدخل مسار LinkedIn  ",
  // "enter.notice.period": "أدخل فترة الإشعار  ",
  // "enter.current.salary": "أدخل الراتب الحالي  ",
  // "enter.expected.salary": "أدخل الراتب المتوقع  ",
  // "enter.additional.info": "أدخل معلومات إضافية  ",

  // //Apply
  // // Apply> errors

  // "err.must.not.empty": "يجب أن لا يكون فارغًا  ",
  // "err.must.be.email": "يجب أن يكون من نوع بريد إلكتروني  ",
  // "err.length.must.10": "يجب أن يكون طوله 10  ",
  // "err.invalid.number": "رقم غير صالح  ",
  // "err.select.field": "حقل اختياري  ",
  // "err.link.github.ptofile": "يجب أن يكون الرابط من نوع ملف جيثب  ",
  // "err.linkedin.link.format":
  //   "يجب أن يكون الرابط من نوع https://www.linkedin.com/...    ",
  // "err.greater.than.1900": "يجب أن يكون السنة أكبر من 1900  ",
  // "err.less.than.220": "يجب أن يكون السنة أقل من 2200  ",
  // "err.try.again.later": "حدث خطأ ما. يرجى المحاولة مرة أخرى في وقت لاحق  ",
  // "err.upload.again": "خطأ في تحميل السيرة الذاتية. يرجى المحاولة مرة أخرى  ",
  // "err.upload.doc.pdf": "يرجى تحميل ملف pdf أو doc  ",
  // "thanks.on.error":
  //   "شكرًا لاهتمامك. نواجه مشكلات تقنية. يرجى إرسال طلبك إلى careers@zopsmart.com    ",

  // dismiss: "رفض  ",
  // "mail.now": "ارسل الآن  ",
  // ok: "حسنًا",
  // "click.to.career": "انقر هنا لصفحة الوظائف  ",
  // "thank.to.apply": "شكرا للتقديم  ",
};

export default messages;
