const messages = {
  // steps
  step1: "Step 1",
  step2: "Step 2",
  step3: "Step 3",

  //HOME > nav bar
  "nav.home": "HOME",
  "nav.products": "PRODUCTS",
  "products.website.mobile": "WEBSITE & MOBILE APPS",
  "products.marketing": "MARKETING",
  "products.operations": "OPERATIONS MANAGEMENT",
  "products.scan.go": "SCAN & GO",
  "products.logistic": "LOGISTICS",
  "products.click.collect": "CLICK & COLLECT",
  "products.analytics": "ANALYTICS",
  "products.customer.support": " CUSTOMER SUPPORT",
  "nav.technology": "TECHNOLOGY",
  "nav.about.us": "ABOUT US",
  "nav.careers": "CAREERS",
  "nav.contact.us": "CONTACT US",
  "select.language.heading": "Select Language",
  //HOME > Banners
  "banner.1.text": "Welcome to tomorrow's retail infrastructure",
  "banner.2.text":
    "Providing end to end coverage of your omni-channel needs with a modular solution that can build on what you have",
  // HOME > Hero Section
  "hero.zopsmart.technology": "ZopSmart Technology",
  "hero.zopsmart.technology.content":
    "Zopsmart helps offline businesses to go online by providing the required tools and techniques to set up their online store. Our goal is to create value and deliver mature, end to end products for digital savvy customer. We show businesses how they can better engage their customers, innovate within predictable budgets by going online with their products in the shortest possible time. By using human-centered design practices, we help customers re-imagine their business, consumer interaction, and proven analytics, with an accelerated time-to-market.",
  //HOME > Industries we specialize in
  "home.industries.specialised": "Industries We Specialize In",
  "industy.retail": "Retail",
  "industy.ecommerce": "E commerce",
  "industy.logistics": "Logistics",
  "industy.supply.chain": "Supply Chain",
  "brands.helped": "Brands we Helped Shaping their Business & Future",
  // HOME > Our Expertise
  "our.expert": "Our Expertise",
  "yr.of.exp": "Years of experience",
  "developing.refining.retail": "Developing & refining retail capabilities",
  "digital.products.created": "Digital products created for our clients",
  "engineers.engaged.retail": "Engineers engaged in retail projects",
  "strong.cleints":
    "Strong clientele that includes top global CPG and Retail giants",
  "dedicated.UI/UX": "Dedicated UI/UX team for design focus",
  "cloud.consulting.partners":
    "Cloud consulting partnerships with AWS, Google Cloud & Microsoft Azure",
  //HOME > Footer
  "footer.whitepaper.download.error":
    "Unable to open the document. Please check your browser settings.",
  "footer.download.whitepaper": "Download Whitepaper",
  "footer.company": "Company",
  "footer.about.us": "About Us",
  "footer.contact.us": "Contact Us",
  "footer.products": "Products",
  "footer.our.team": "Our Team",
  "footer.privacy.policy": "Privacy Policy",
  "footer.stay.in.touch": "Stay in touch",
  "footer.facebook": "facebook",
  "footer.linkedIn": "linkedIn",
  "footer.twitter": "twitter",
  "footer.raramuri": "Raramuri Technology Private Limited,",
  "footer.rights": " All rights reserved",
  // product section
  products: "PRODUCTS",
  "products.description":
    "World’s best E-commerce software for Brands and Retailers. Deliver within hours at lowest cost using your existing retail network",
  "products.eazy": "SMARTSTORE EAZY",
  "products.enterprise": "SMARTSTORE ENTERPRISE",
  "products.productfinder.heading1": "Large Store",
  "products.productfinder.heading2": "Smart Product- <br /> locator",
  "products.productfinder.description":
    "Own a Large Grocery or Departmental Store? Help your customers navigate through your shop and find what they are looking for easily.",
  "products.zoppay.heading1": "Payment Gateway",
  "products.zoppay.heading2": "Smart Payment Gateway",
  "products.zoppay.description":
    "One step integration to accept online payments from gateways of your choice.",
  "products.intellipos.heading1": "Self Sustaining Store",
  "products.intellipos.heading2": "Smart Point-of-Sale",
  "products.intellipos.description":
    "An intelligent POS system that allows the user and the operator to simultaneously operate and use the system with complementary information on Dual Screens.",
  "products.groceryexpress.heading1": "IOT Device",
  "products.groceryexpress.heading2": "Grocery Express",
  "products.groceryexpress.description":
    "One of it's kind. multi-SKU ordering IOT device that connects to a ZopNow account and allows the user to order from a refrigerator door or any surface for that matter.",
  // products website and mobile apps
  "products.websiteandmobileapps.title": "Website & Mobile Apps",
  "products.websiteandmobileapps.websiteService.item1":
    "Our front end solution can be delivered across Desktops & Mobile (Android & iOS), in a matter of weeks, to provide customers with a unified experience.",
  "products.websiteandmobileapps.websiteService.item2":
    "With the “Cart on the Cloud”, all family members can add to the “family cart” from their own devices at any time",
  "products.websiteandmobileapps.languageservice.description":
    "Easily allow customers to toggle between languages (without disrupting your ERP). Ideal for retailers serving different communities (e.g. Local & ExPat).",

  // products marketing
  "products.marketing.title": "Marketing",
  "products.marketing.personalisation.heading": "Personalisation",
  "products.marketing.personalisation.item1":
    "Unique results, based on individual buying activity, are shown for both “search” and “substitutions”.",
  "products.marketing.personalisation.item2":
    "If a customer orders a product that has recently had fulfilment issues, our system pro-actively suggests personalised “substitutes” that are pre-confirmed as a preferred backup.",
  "products.marketing.recommendation.heading": "Recommendation",
  "products.marketing.recommendation.description":
    "Our proprietary algorithms leverage a range of variables including shopping history, frequency, recency etc. to subtly influence consumer behaviour based on thousand of similar consumers. Our algorithms have been developed by the same team that power Amazon’s personalisation and recommendation engines. These engines can be integrated with your existing front end solution.",
  "products.marketing.promotion.heading": "Promotion Campaigns",
  "products.marketing.promotion.description":
    "We segment the customer base in real time, based on multiple parameters that combine the actions & needs of the consumer with their location to identify the most promising coupons / offers.",
  "products.marketing.loyalty.heading": "Loyalty",
  "products.marketing.loyalty.description":
    "Which can be delivered across multiple venues as part of a loyalty program administered by ZopSmart or integrated with your existing administrator.",
  // products opertation and management page
  "products.operationsmanagement.title": "Operations Management",
  "products.operationsmanagement.heading": "Allocate and Track Orders",
  "products.operationsmanagement.item1":
    "Automatically allocate online orders to stores and individual staff members.",
  "products.operationsmanagement.item2":
    "Track the real-time status of all live orders.",
  "products.operationsmanagement.planning.heading": "Planning",
  "products.operationsmanagement.planning.description":
    "A mobile app shows the staff member the optimal route for picking items balancing time and packing requirements.",
  "products.operationsmanagement.picking.heading": "Pick and Scan In Real-Time",
  "products.operationsmanagement.picking.description":
    "Staff member picks and scans in real-time:",
  "products.operationsmanagement.picking.item1":
    "Picking errors immediately flagged and corrected.",
  "products.operationsmanagement.picking.item2":
    "Receipt built along the way, Staff member is guided on substitutions, removing need for customer calls.",
  "products.operationsmanagement.packing.heading": "Packing",
  "products.operationsmanagement.packing.description":
    "Staff member given clear instructions on how to pack (which items in which box) and where to store.",
  "products.operationsmanagement.selfupdatedesc.heading":
    "Real Time Shelf Updates",
  "products.operationsmanagement.selfupdatedesc.item1":
    "While staff pick from shelves, any out of stock items can be escalated in real time via their mobile app.",
  "products.operationsmanagement.selfupdatedesc.item2":
    "If inventory is in the back, shelves can be filled on a more timely basis.",
  // products scan and go page
  "products.scanandgo.title": "Scan and Go",
  "products.scanandgo.scansteps.heading": "Its Easy to use",
  "products.scanandgo.scansteps.step1":
    "Customer Launches App and (GPS enables ”Check In”)",
  "products.scanandgo.scansteps.step2":
    "Customer scans and bags items on their phone as they go.",
  "products.scanandgo.scansteps.step3":
    "Customer pays in-app and verifies the e-receipt.",
  // products logistic page
  "products.logistics.title": "Logistics",
  "products.logistics.booking.description": "Booking",
  "products.logistics.booking.item1": "Customer picks preferred slot.",
  "products.logistics.booking.item2":
    "Incentives offered to influence which time slots are chosen.",
  "products.logistics.booking.item3":
    "30 minute delivery window provided to customer.",
  "products.logistics.planning.description": "Planning",
  "products.logistics.planning.item1": "Dynamic clustering of orders.",
  "products.logistics.planning.item2": "Target 100% capacity utilisation.",
  "products.logistics.planning.item3": "Suggest route for shortest turnaround.",
  "products.logistics.delivery.description": "Delivery Optimisation",
  "products.logistics.delivery.item1":
    "Auto assigns vehicle and delivery driver.",
  "products.logistics.delivery.item2": "Integrated map shows route.",
  "products.logistics.delivery.item3": "On-the-spot product returns.",
  "products.logistics.delivery.item4": "Watertight accounting of returns.",
  "products.logistics.tracking.description": "Tracking",
  "products.logistics.tracking.item1":
    "Route Monitoring for both Customer and Manager.",
  "products.logistics.tracking.item2": "Pro-actively send alerts to customer.",
  // products click and collect page
  "products.collectandgo.title": "Click and Collect",
  "products.collectandgo.booking.description": "Booking",
  "products.collectandgo.booking.item1": "Customer places order.",
  "products.collectandgo.booking.item2": "Pick up time allocated.",
  "products.collectandgo.booking.item3": "Option to add order to Maps.",
  "products.collectandgo.trackingandnotification.description":
    "Tracking & Notification",
  "products.collectandgo.trackingandnotification.item1":
    "Customer can manage order and track status through an App.",
  "products.collectandgo.trackingandnotification.item2":
    "Notification received once order is ready.",
  "products.collectandgo.drivetostore.description": "Drive To Store",
  "products.collectandgo.drivetostore.item1":
    "Use Maps App to navigate to store.",
  "products.collectandgo.drivetostore.item2":
    "Share arrival time with store while travelling (updated in real time).",
  "products.collectandgo.collectgoods.description": "Collect Goods",
  "products.collectandgo.collectgoods.item1": "Confirm arrival via App.",
  "products.collectandgo.collectgoods.item2": "Confirm Pick Up Point.",
  "products.collectandgo.collectgoods.item3": "Customer receives delivery.",
  // products analytics
  "products.analytics.title": "Analytics",
  "products.analytics.newproduct.heading": "New product",
  "products.analytics.newproduct.description1":
    "Use data analytics to identify opportunities for increased sales through new products / product ranges.",
  "products.analytics.newproduct.description2":
    "E.g: If Sales of a specific product are low in a given store, explore if the community (near the store) is likely to resonate with a different brand.",
  "products.analytics.workforcemanagement.heading": "Workforce Management",
  "products.analytics.workforcemanagement.description1":
    "Role specific KPI tracking at both employee and store to inform training and incentive opportunities.",
  "products.analytics.workforcemanagement.description2":
    "E.g: Picking time for a given  SKU; number of damaged products by delivery driver; wastage by store.",
  "products.analytics.skueconomics.heading": "SKU Level Economics",
  "products.analytics.skueconomics.description1":
    "Insights into all the cost drivers for a given SKU to inform pricing strategy.",
  "products.analytics.skueconomics.description2":
    "E.g: By understanding some products require more time for picking, may decide to increase the price to off-set.",
  // products customerSupport
  "products.customersupport.title": "Customer Support",
  "products.customersupport.selfhelp.heading":
    "Empowering customers to self-service",
  "products.customersupport.selfhelp.item1": "Change orders / delivery times",
  "products.customersupport.selfhelp.item2": "Report issues with order",
  "products.customersupport.selfhelp.item3": "Change contact details",
  "products.customersupport.selfhelp.item4": "Check status",
  "products.customersupport.supportstaff.heading":
    "Supplemented by robust tools for support staff",
  "products.customersupport.workflowmanagement.heading":
    "Workflow Management for call center staff",
  "products.customersupport.workflowmanagement.item1":
    "Auto-route calls / mails to specific teams",
  "products.customersupport.workflowmanagement.item2":
    "Reconnect customers to the same staff to provide continuity",
  "products.customersupport.workflowmanagement.item3":
    "Instant access to customer detail.",
  "products.customersupport.disputemanagement.heading": "Dispute Management",
  "products.customersupport.disputemanagement.description":
    "Rule based dispute management system, providing different resolution paths for different customer segments (e.g. Gold vs. Silver customers).",
  "products.customersupport.notifications.heading": "Notifications",
  "products.customersupport.notifications.description":
    "Ability to notify sub-groups of customers (e.g. “Deliveries delayed because of vehicle breakdown” sent to all impacted customers)",
  // products footer
  "products.footer.heading": "Do you need more information?",
  "products.footer.description":
    " Just leave your contact details below and our e-commerce expert will get in touch with you at the earliest",
  "products.footer.fullname": "Full Name",
  "products.footer.mobilenumber": "Mobile Number",
  "products.footer.emailaddress": "Email Address",
  "products.footer.message": "Message",
  "products.footer.tellmemore": "TELL ME MORE",

  //services
  services: "SERVICES",
  "services.acceleratingdevelopmentcycles": "ACCELERATING DEVELOPMENT CYCLES",
  "services.microservicesapplicationenvironment":
    "MICROSERVICES APPLICATION ENVIRONMENT",
  "services.deliverypartnerintegration": "DELIVERY PARTNER INTEGRATION",
  "services.processscaling": "PROCESS SCALING",
  "services.cloudmigration": "CLOUD MIGRATION",

  //services  accelrating development cycles
  "services.acceleratingdevelopmentcycles.title":
    "Accelerating Development Cycles",
  "services.acceleratingdevelopmentcycles.devdesc.description1":
    "Each development team at our client was separately responsible for implementing core components in line with guidelines.",
  "services.acceleratingdevelopmentcycles.devdesc.description2":
    "Notable issues to arise were inconsistent adoption of the guidelines, and changes to the components needing to be replicated across all systems.",
  "services.acceleratingdevelopmentcycles.devdesc.description3":
    "Over the course of 6 – 8 weeks ZopSmart deployed a central framework and library which is called as required.",
  "services.acceleratingdevelopmentcycles.steps.step1": "Dev Team 1",
  "services.acceleratingdevelopmentcycles.steps.step2": "Dev Team 2",
  "services.acceleratingdevelopmentcycles.steps.step3": "Dev Team 3",

  //services mcroservices application environment
  "services.microservicesapplicationenvironment.title":
    "Microservices Application Environment",
  "services.microservicesapplicationenvironment.servicesegment.description1":
    "Our client wanted to simplify how code within existing systems could be re-used and also reduce the potential scale of risks arising from code changes.",
  "services.microservicesapplicationenvironment.servicesegment.description2":
    "Over the course of ~6 months ZopSmart restructured the legacy systems and converted monolithic legacy systems into a series of callable micro-services.",
  "services.microservicesapplicationenvironment.servicesegment.description3":
    "As a result, the client has seen reductions in new development times of up to 30%",
  "services.microservicesapplicationenvironment.monolithic":
    "Monolithic Legacy Systems",
  "services.microservicesapplicationenvironment.microservices": "Microservices",

  // services delivery partner integration
  "services.deliverypartnerintegration.title": "Delivery Partner Integration",
  "services.deliverypartnerintegration.description1":
    "Our client had been executing integrations with delivery partners (Instacart, Shipt etc) on a case by case basis, resulting in inefficient integration and inconsistent information flows (e.g. frequency of inventory / pricing updates varied by partner).",
  "services.deliverypartnerintegration.description2":
    "ZopSmart created a common “Partner Integration Layer” (delivered in less than 3 months) and migrated all existing partners into the new framework.",
  "services.deliverypartnerintegration.header.title":
    "Ad-Hoc Partner Integration",
  "services.deliverypartnerintegration.header.title2":
    "Partner Integration Layer",

  // services process scaling
  "services.processscaling.title": "Process Scaling",
  "services.processscaling.descripiton1":
    "Our client’s category managers / marketing department managed all offers through Excel based tools with forecasting & reporting requiring IT to run ad-hoc queries of the ERP system.",
  "services.processscaling.descripiton2":
    "Apart from being inefficient, the absence of flexible query tools meant there was a higher risk of offers being independently generated that sometimes conflicted with each other.",
  "services.processscaling.interface.header":
    " <span>ZopSmart developed</span><span>“Engines”</span><span>spanning offer generation, forecasting and reporting which could be operated by. </span>",

  // services cloud migration
  "services.cloudmigration.title": "Cloud Mirgration",
  "services.cloudmigration.description1":
    "Our client is on a multi-year journey to migrate all systems from on-premises to the cloud as they sought reduced costs and faster development cycles through:",
  "services.cloudmigration.description.item1": "Flexible storage capacity",
  "services.cloudmigration.description.item2": "Flexible compute capacity",
  "services.cloudmigration.description.item3":
    "Easy access to a wide range of Cloud Native development & security tools",
  "services.cloudmigration.description2":
    " With our deep understanding of both Cloud and retail technology systems, ZopSmart have been selected to partner with the in-house development team on both the planning and execution of migration projects.",
  "services.cloudmigration.trustedpartner.heading": "Trusted Partner for",
  "services.cloudmigration.trustedpartner.description1":
    "Determining and prioritising migration requirements",
  "services.cloudmigration.trustedpartner.description2":
    "Selecting the target cloud environment",
  "services.cloudmigration.trustedpartner.description3":
    "Planning the migration",
  "services.cloudmigration.trustedpartner.description4":
    "Executing the migration & maintaining the cloud environment",

  // Technology
  "zopSmart.technology": "ZopSmart Technology",
  "technology.Description":
    "Cutting-edge technology that is right for your business",
  "what.we.offer": "What we offer",
  "what.we.offer.description":
    "We are using one of the most advanced technology stack for  an ecommerce platform. We have built the platform using  ReactJS, NextJS, GoLang, NodeJS, Java, SQL and NoSQL  Database, Redis Cluster for Cache, Solr Cluster for Search  and Neo4j Graph Database for Personalization. We host our  platform on Google, Amazon, and Azure cloud services that  ensures more than 99.99% uptime and accessibility to the  application from anywhere, anytime and any device.",
  // Technology > Mainstays of our Technology
  "mainstays.technology": "Mainstays of our Technology",
  proven: "Proven",
  "proven.description":
    "Our technology is in action from 7 years, handling thousands of transactions each day. Our clients include large retailers in Asia, including two of the largest e-grocers in India.",

  "low.cost": "Low Cost",
  "low.cost.description":
    "With us you get enterprise-class technology at an affordable price. You save substantial capital as well as variable costs with zero in-house server, application and personnel requirements.",
  "zero.time.to.market": "Zero Time-to-Market",
  "zero.time.to.market.description":
    "With us you get to market in almost zero time, as we already have almost all the e-commerce capabilities built and available off-the-shelf.",
  "flexible.&.scalable": "Flexible & Scalable",
  "flexible.&.scalable.description":
    "Our platform is ideal for businesses which have uncertain or fluctuating demand. If your needs increase we will automatically scale up your server capacity and vice-versa.",
  manageable: "Manageable",
  "manageable.description":
    "Our platform provides enhanced & simplified IT management & maintenance capabilities through central administration of resources, managed infrastructure & SLA backed support services.",
  secure: "Secure",
  "secure.description":
    "All your data is encrypted and stored in Google or Amazon cloud that have more than 99.99% uptime. You get complete data security even in extreme situations of power failures or natural disasters.",
  "manage.on.the.go": "Manage-on-the-go",
  "manage.on.the.go.description":
    "With our platform, if you’ve got a smartphone and an internet connection you can be at work. Our back-end interface can be conveniently accessed via browser on any device.",
  "automatic.updates": "Automatic Updates",
  "automatic.updates.description":
    "We take care of development and roll-out of regular software updates including security updates. Hence you don’t have to worry about maintaining your applications and servers",
  modular: "Modular",
  "modular.description":
    "Our software is truly modular. Hence if you have an existing e-commerce solution and you want to add only a few modules of our solution then also we fit your needs perfectly.",

  "building.blocks.our.technology": "Building blocks of our Technology",

  // About Us
  "about.us.description":
    "We envision to be the #1 retail technology platform of the world.",
  "our.experties.description":
    "in the retail domain is built on our team’s immense experience in the field of FMCG, retail, supply-chain and logistics. All our experience has been baked into business processes that are embedded in our products.",
  "our.solution": "Our Solution",
  "our.solution.description":
    "has been built over 7 years and is handling thousands of transactions every day. Our suite of products are one of the  most advanced, stable and scalable solutions available in the  world of retail technology.",
  "our.team": "Our Team",
  "our.team.description":
    "Consists of passionate retail professionals who hail from world’s most prestigious educational institutions such as IIT, IIM, MIT, ISB and Wharton.",
  mukesh: "Mukesh Singh",
  "mukesh.designation": "Founder",
  "mukesh.description":
    "has a B.Tech in Computer Science from IIT, Kanpur. After his UG he pursued his PhD at MIT, but dropped-out to do an animation startup - Zoesis in Boston. Later he worked for eGain, Amazon and MakeMyTrip before starting the development of SmartStore E-commerce platform.",
  raj: "Raj Pandey",
  "raj.designation": "CEO",
  "raj.description":
    "has a B.Tech from IIT Kanpur and an MBA from Wharton School of Business. After a decade of deal-making on Wall Street as investment banker, Raj has returned to India to take the SmartStore E-commerce platform to international markets.",
  vikash: "Vikash Kumar",
  "vikash.designation": "CTO",
  "vikash.description":
    "has a B.Tech from IIT Bombay and one of the very few who are equally good with the camera and poetry. In office we refer to him as bond.",
  sanket: "Sanket Mehta",
  "sanket.designation": "Operations Head",
  "sanket.description":
    "has a B.Tech from IIT Bombay. Ops King, most of the time you can see him running things calmly and silently.",
  rohit: "Rohit Saksena",
  "rohit.designation": "Head - Business Solutions",
  "rohit.description":
    "has an MBA from Indian School of Business, Hyderabad. He has worked in consulting, planning and analytics designations earlier with organizations including Dell, TVS Logistics and Wipro.",

  // career
  "work.with.us": "Work with us.",
  "work.with.us.description":
    "We are Bangalore based company. We are ready to hire new talents to join us.",
  "about.working.here": "About Working here",
  "about.working.here.description": "Stand out from the pack with your talent",
  "conducive.environment": "Conducive Environment",
  "conducive.environment.description":
    "Enjoy challenging work, and a satisfying job without any hierarchy",
  "best.gadgets": "Best Gadgets",
  "best.gadgets.description":
    "Pamper the tech savvy in you with gadgets and advanced technology",
  "medical.insurance": "Medical Insurance",
  "medical.insurance.description":
    "We have got you and your family covered literally, with best in class insurance",
  "snacks.drinks": "Healthy snacks and drinks",
  "snacks.drinks.description": "Food is in the house, so you feel like house",
  "gilly's": "@Gilly's Redefined",
  "@PLaY.Arena": "@PLaY Arena",
  "@Koramangala.Social": "@Koramangala Social",
  "@MACAW.By.Stories": "@MACAW By Stories",
  "team.lunch": "Team lunch",
  "team.outing": "Team outing",
  area83: "@Area 83",
  "mango.mist": "@MangoMist",
  "join.zopsmart": "Want to join the team ZopSmart",
  "join.zopsmart.description":
    "Great Technology. Amazing Colleagues. Discover the world of innovation, learning and growth with us",
  "no.jobs": "No job opening as of now",
  apply: "Apply",

  // contact us
  "contact.us.description":
    "Our retail technology experts are available 24 X 7",
  "write.us": "Write to us",
  "write.us.description":
    "Please get in touch if you need any help on our solutions or need to know anything else in the domain of retail technology.",
  "submit.form.error":
    "Oops! Form submission failed. Please email your queries to",
  submitting: "Submitting",
  submit: "Submit",
  "thank.you": "Thank you",
  "will.get.in.touch": "We will get in touch soon",
  address: "Address",
  "zs.address":
    "2078, 24th Main Rd, Vanganahalli, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102.",
  mobile: "Mobile",
  "email.id": "Email ID",

  name: "Name",
  "upload.resume": "Upload Resume",
  "drag.upload.resume": "Drag or Upload your resume",
  "current.location": "Current Location",
  "notice.period": "Notice Period",
  "current.salary": "Current Salary",
  "in.lpa": "In LPA",
  "expected.salary": "Expected Salary",
  "additional.info": "Additional Information",
  "cance;": "Cancel",
  save: "Save",
  "enter.location": "Enter your current location",
  "enter.linkedin.profile": "Enter linkedIn path",
  "enter.notice.period": "Enter Notice Period ",
  "enter.current.salary": "Enter Current Salary",
  "enter.expected.salary": "Enter Expected Salary",
  "enter.additional.info": "Enter Additional Information",

  //Apply
  // Apply> errors

  "err.must.not.empty": "* must be non empty",
  "err.must.be.email": "* must be of type email",
  "err.length.must.10": "* must be of length 10",
  "err.invalid.number": "* invalid number",
  "err.select.field": "* select field",
  "err.link.github.ptofile": "* link must be of type github profile",
  "err.linkedin.link.format":
    "* link must be of type https://www.linkedin.com/...",
  "err.greater.than.1900": "* year should be greater than 1900",
  "err.less.than.220": "* year should be less than 2200",
  "err.try.again.later": "Something went wrong please try again later",
  "err.upload.again": "Error in uploading resume please try again",
  "err.upload.doc.pdf": "Please Upload pdf or doc file",
  "thanks.on.error":
    "Thank you for your interest. We're experiencing technical issues. Please send your application to careers@zopsmart.com",

  dismiss: "Dismiss",
  "mail.now": "Mail Now",
  ok: "Ok",
  "click.to.career": " click here for CAREERS page",
  "thank.to.apply": "Thank you for apply",

  // privacy policy

  "privacy.policy.p1":
    "Your privacy is important to us. It is Zopsmart's policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, <a href='https://zopsmart.com'> https://zopsmart.com</a>, and other sites we own and operate.",
  "privacy.policy.p2":
    "This policy is effective as of 25 June 2021 and was last updated on 25 June 2021.",
  "info.we.collect": "Information We Collect",
  "info.we.collect.p1":
    "Information we collect includes both information you knowingly and actively provide us when using or participating in any of our services and promotions, and any information automatically sent by your devices in the course of accessing our products and services.",
  "log.data": "Log Data",
  "log.data.p1":
    "When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your device’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, other details about your visit, and technical details that occur in conjunction with any errors you may encounter.",
  "log.data.p2":
    "Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons.",
  "personal.info": "Personal Information",
  "personal.info.p1":
    "We may ask for personal information which may include one or more of the following:",
  "personal.info.p1.li1": "Name",
  "personal.info.p1.li2": "Email",
  "personal.info.p1.li3": "Social media profiles",
  "reasons.for.processing.personal.info":
    "Legitimate Reasons for Processing Your Personal Information",
  "reasons.for.processing.personal.info.p1":
    "We only collect and use your personal information when we have a legitimate reason for doing so. In which instance, we only collect personal information that is reasonably necessary to provide our services to you.",
  "collection.use.info": "Collection and Use of Information",
  "collection.use.info.p1":
    "We may collect personal information from you when you do any of the following on our website:",
  "collection.use.info.p1.li1":
    "Use a mobile device or web browser to access our content",
  "collection.use.info.p1.li2":
    "Contact us via email, social media, or on any similar technologies",
  "collection.use.info.p1.li3": "When you mention us on social media",
  "collection.use.info.p2":
    "We may collect, hold, use, and disclose information for the following purposes, and personal information will not be further processed in a manner that is incompatible with these purposes:",
  "collection.use.info.p2.li1":
    "to enable you to access and use our website, associated applications, and associated social media platforms",
  "collection.use.info.p3":
    "Please be aware that we may combine information we collect about you with general information or research data we receive from other trusted sources.",
  "security.your.personal.info": "Security of Your Personal Information",
  "security.your.personal.info.p1":
    "When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.",
  "security.your.personal.info.p2":
    "Although we will do our best to protect the personal information you provide to us, we advise that no method of electronic transmission or storage is 100% secure, and no one can guarantee absolute data security. We will comply with laws applicable to us in respect of any data breach.",
  "security.your.personal.info.p3":
    "You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the bounds of our services.",
  "how.log.keep.personal.info": "How Long We Keep Your Personal Information",
  "how.log.keep.personal.info.p1":
    "We keep your personal information only for as long as we need to. This time period may depend on what we are using your information for, in accordance with this privacy policy. If your personal information is no longer required, we will delete it or make it anonymous by removing all details that identify you.",
  "how.log.keep.personal.info.p2":
    "However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or historical research purposes or statistical purposes.",
  "children.privacy": "Children’s Privacy",
  "children.privacy.p1":
    "We do not aim any of our products or services directly at children under the age of 13, and we do not knowingly collect personal information about children under 13.",
  "disclose.personal.info.to.third.party":
    "Disclosure of Personal Information to Third Parties",
  "disclose.personal.info.to.third.party.p1":
    "We may disclose personal information to:",
  "disclose.personal.info.to.third.party.p1.li1":
    "a parent, subsidiary, or affiliate of our company",
  "disclose.personal.info.to.third.party.p1.li2":
    "third party service providers for the purpose of enabling them to provide their services, for example, IT service providers, data storage, hosting and server providers, advertisers, or analytics platforms",
  "disclose.personal.info.to.third.party.p1.li3":
    "our employees, contractors, and/or related entities",
  "disclose.personal.info.to.third.party.p1.li4":
    "our existing or potential agents or business partners",
  "disclose.personal.info.to.third.party.p1.li5":
    "sponsors or promoters of any competition, sweepstakes, or promotion we run",
  "disclose.personal.info.to.third.party.p1.li6":
    "courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise, or defend our legal rights",
  "disclose.personal.info.to.third.party.p1.li7":
    "third parties, including agents or sub-contractors, who assist us in providing information, products, services, or direct marketing to you third parties to collect and process data",
  "international.transfer.personal.info":
    "International Transfers of Personal Information",
  "international.transfer.personal.info.p1":
    "The personal information we collect is stored and/or processed where we or our partners, affiliates, and third-party providers maintain facilities. Please be aware that the locations to which we store, process, or transfer your personal information may not have the same data protection laws as the country in which you initially provided the information. If we transfer your personal information to third parties in other countries: (i) we will perform those transfers in accordance with the requirements of applicable law; and (ii) we will protect the transferred personal information in accordance with this privacy policy.",
  "right.controlling.personal.info":
    "Your Rights and Controlling Your Personal Information",
  "right.controlling.personal.info.p1":
    "You always retain the right to withhold personal information from us, with the understanding that your experience of our website may be affected. We will not discriminate against you for exercising any of your rights over your personal information. If you do provide us with personal information you understand that we will collect, hold, use and disclose it in accordance with this privacy policy. You retain the right to request details of any personal information we hold about you.",
  "right.controlling.personal.info.p2":
    "If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.",
  "right.controlling.personal.info.p3":
    "If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time. We will provide you with the ability to unsubscribe from our email-database or opt out of communications. Please be aware we may need to request specific information from you to help us confirm your identity.",
  "right.controlling.personal.info.p4":
    "If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us using the details provided in this privacy policy. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or out of date.",
  "right.controlling.personal.info.p5":
    "If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.",
  "use.of.cookies": "Use of Cookies",
  "use.of.cookies.p1":
    "We use  &ldquo;cookies&rdquo; to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified.",
  "limits.of.policy": "Limits of Our Policy",
  "limits.of.policy.p1":
    "Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.",
  "changes.in.policy": "Changes to This Policy",
  "changes.in.policy.p1":
    "At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we will post the changes here at the same link by which you are accessing this privacy policy.",
  "changes.in.policy.p2":
    "If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information.",
  "contact.us.p1":
    "For any questions or concerns regarding your privacy, you may contact us using the following details:",
  "zs.legal.team": "Zopsmart Legal Team",
  "legal.mail": "legal@zopsmart.com",
};

export default messages;
