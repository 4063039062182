import React, { Component } from "react";
import Link from "gatsby-link";
import logo from "./zopsmart-final-logo.svg";
import zopsmartLogo from "./zopsmart-logo.svg";
import "./header.css";
import { navigate } from "gatsby";
import LanguageIcon from "./LanguageIcon";
import Tick from "./tick.webp";
import { Message } from "../lib/getMessage";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.hide = this.hide.bind(this);
  }
  componentDidMount() {
    window.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClick);
  }

  toggle() {
    this.setState(
      (prevState) => {
        return {
          expanded: !this.state.expanded,
        };
      },
      () => {
        document.body.style.overflow = this.state.expanded ? "hidden" : "auto";
      }
    );
  }

  hide() {
    this.setState({
      expanded: false,
    });
    document.body.style.overflow = "auto";
  }

  handleClickNavigate = (event, lang) => {
    event.preventDefault();
    navigate(`${lang}/about`); // Navigate to the desired path
  };

  handleClick(e) {
    if (this.toggleRef.contains(e.target)) {
      this.toggle();
    } else {
      this.hide();
    }
  }
  render() {
    let { current, eazy, language, updateLanguage } = this.props;
    const lang = language ? `/${language}` : "";
    const { expanded } = this.state;
    let image = eazy ? logo : zopsmartLogo;

    const allLanguages = [
      { code: "en", name: "English" },
      { code: "ar", name: "عربي" },
    ];
    const isRTL = language === "ar";
    return (
      <header className="common-header">
        <div>
          <div className="wrap">
            <Link to={`${lang}/`} className="logo-link">
              <img
                className="logo"
                src={image}
                alt="ZopSmart Logo"
                loading="lazy"
              />
            </Link>
            <div className="menu-button-container">
              <div
                className={`hamburger ${expanded ? "close" : ""}`.trim()}
                ref={(node) => {
                  this.toggleRef = node;
                }}
              >
                <span />
                <span />
                <span />
              </div>
              <nav className={`site-nav ${expanded ? "expanded" : ""}`.trim()}>
                <ul className="nav-options">
                  <li>
                    <Link
                      to={`${lang}/`}
                      className={`${
                        current === "home" ? "current" : ""
                      }`.trim()}
                    >
                      <Message dictKey="nav.home" />
                    </Link>
                  </li>
                  <li
                    className={
                      "submenu-link" +
                      (current === "product" ? " active-submenu" : "")
                    }
                    ref={(node) => {
                      this.dropdownRef = node;
                    }}
                  >
                    <Link
                      to={`${lang}/product/website-and-mobile-app`}
                      className={`${current === "product" ? "current" : ""}`}
                    >
                      <Message dictKey="nav.products" />
                    </Link>
                    {/* <span>PRODUCTS</span> */}
                    <ul className={`submenu ${isRTL && "submenu-rtl"}`}>
                      <li className={`${isRTL ? "productsRTL" : ""}`}>
                        <Link to={`${lang}/product/website-and-mobile-app`}>
                          <Message dictKey="products.website.mobile" />
                        </Link>
                      </li>
                      <li className={`${isRTL ? "productsRTL" : ""}`}>
                        <Link to={`${lang}/product/marketing`}>
                          <Message dictKey="products.marketing" />
                        </Link>
                      </li>
                      <li className={`${isRTL ? "productsRTL" : ""}`}>
                        <Link to={`${lang}/product/operations-management`}>
                          <Message dictKey="products.operations" />
                        </Link>
                      </li>
                      <li className={`${isRTL ? "productsRTL" : ""}`}>
                        <Link to={`${lang}/product/scan-and-go`}>
                          <Message dictKey="products.scan.go" />
                        </Link>
                      </li>
                      <li className={`${isRTL ? "productsRTL" : ""}`}>
                        <Link to={`${lang}/product/logistics`}>
                          <Message dictKey="products.logistic" />
                        </Link>
                      </li>
                      <li className={`${isRTL ? "productsRTL" : ""}`}>
                        <Link to={`${lang}/product/click-and-collect`}>
                          <Message dictKey="products.click.collect" />
                        </Link>
                      </li>
                      <li className={`${isRTL ? "productsRTL" : ""}`}>
                        <Link to={`${lang}/product/analytics`}>
                          <Message dictKey="products.analytics" />
                        </Link>
                      </li>
                      <li className={`${isRTL ? "productsRTL" : ""}`}>
                        <Link to={`${lang}/product/customer-support`}>
                          <Message dictKey="products.customer.support" />
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* <li
                    className={
                      "submenu-link" +
                      (current === "service" ? " active-submenu" : "")
                    }
                    ref={(node) => {
                      this.dropdownRef = node;
                    }}
                  >
                    <Link
                      to="/service/development-cycles"
                      className={`${current === "service" ? "current" : ""}`}
                    >
                     <Message dictKey="services"/>
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link to="/service/development-cycles">
                        <Message dictKey="services.acceleratingdevelopmentcycles"/>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service/microservice-application-environment">
                        <Message dictKey="services.microservicesapplicationenvironment"/>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service/delivery-partner-integration">
                        <Message dictKey="services.deliverypartnerintegration"/>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service/process-scaling">
                        <Message dictKey="services.processscaling"/>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service/cloud-migration">
                        <Message dictKey="services.cloudmigration"/>
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                  <li>
                    <Link
                      to={`${lang}/technology`}
                      className={`${current === "technology" ? "current" : ""}`}
                    >
                      <Message dictKey="nav.technology" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${lang}/about`}
                      className={`${current === "about" ? "current" : ""}`}
                    >
                      <Message dictKey="nav.about.us" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${lang}/careers`}
                      className={`${current === "careers" ? "current" : ""}`}
                    >
                      <Message dictKey="nav.careers" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${lang}/contact`}
                      className={`${current === "contact" ? "current" : ""}`}
                    >
                      <Message dictKey="nav.contact.us" />
                    </Link>
                  </li>
                  <li className="language-toggler">
                    <div id="language-toggler-icon">
                      <LanguageIcon />
                    </div>
                    <ul
                      className={`submenu ${
                        isRTL ? "submenu-lang-rtl" : "submenu-lang-ltr"
                      }`}
                    >
                      {allLanguages.map(({ code, name }) => (
                        <li
                          className={code === language ? "selected-lang" : ""}
                          key={code}
                          onClick={() => updateLanguage(code)}
                        >
                          <Link id="language-toggler-options">
                            <span>{name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
