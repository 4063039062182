import React, { Component } from "react";
import Helmet from "react-helmet";
import Link from "gatsby-link";
import logo from "./zopsmart-logo-svg.svg";
import "../pages/style.css";
import zopsmartFavicon from "../../zopsmart.svg";
import loadable from "@loadable/component";
import WhitePaperPdf from "../../ZopSmartWhitePaper.pdf";
import { Message } from "./../lib/getMessage";
const FooterForm = loadable(() => import("../components/footer-form"));

export default class extends Component {
  handleWhitePaperClick = () => {
    const newWindow = window.open();
    if (newWindow) {
      newWindow.location.href = WhitePaperPdf;
    } else {
      throw new Error(
        "Unable to open the document. Please check your browser settings."
      );
    }
  };

  render() {
    const { showFooterForm, language, direction } = this.props;
    const lang = language ? `/${language}` : "";
    const isDirectionRTL = direction === "rtl";
    return (
      <div>
        <Helmet
          title="ZopSmart"
          link={[
            {
              rel: "icon",
              type: "image/svg",
              href: `${zopsmartFavicon}`,
            },
          ]}
        />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700"
          rel="stylesheet"
        />
        {this.props.children}
        <footer>
          <div className="wrap">
            <div className="tc cta">
              {showFooterForm ? (
                <FooterForm />
              ) : (
                <button
                  onClick={this.handleWhitePaperClick}
                  className="whitepaper-link"
                >
                  <Message dictKey="footer.download.whitepaper" />
                </button>
              )}
            </div>
            <div className="footer-nav">
              <div className="nav-block">
                <h3>
                  <Message dictKey="footer.company" />
                </h3>
                <ul>
                  <li>
                    <Link to={`${lang}/about`}>
                      <Message dictKey="nav.about.us" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${lang}/about#team`}>
                      <Message dictKey="footer.our.team" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${lang}/privacy-policy`}>
                      <Message dictKey="footer.privacy.policy" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="nav-block">
                <h3>
                  <Message dictKey="footer.products" />
                </h3>
                <ul>
                  <li>
                    <Link to={`${lang}/product/website-and-mobile-app`}>
                      <Message dictKey="products.website.mobile" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${lang}/product/marketing`}>
                      <Message dictKey="products.marketing" />
                    </Link>
                  </li>

                  <li>
                    <Link to={`${lang}/product/operations-management`}>
                      <Message dictKey="products.operations" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${lang}/product/scan-and-go`}>
                      <Message dictKey="products.scan.go" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${lang}/product/logistics`}>
                      <Message dictKey="products.logistic" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${lang}/product/click-and-collect`}>
                      <Message dictKey="products.click.collect" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${lang}/product/analytics`}>
                      <Message dictKey="products.analytics" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${lang}/product/customer-support`}>
                      <Message dictKey="products.customer.support" />
                    </Link>
                  </li>
                </ul>
              </div>

              {/* <div className="nav-block">
                <h3>Services</h3>
                <ul>
                  <li>
                    <Link to="/service/development-cycles">
                      Accelerating Development Cycles
                    </Link>
                  </li>
                  <li>
                    <Link to="/service/microservice-application-environment">
                      Microservices Application Environment
                    </Link>
                  </li>
                  <li>
                    <Link to="/service/delivery-partner-integration">
                      Delivery Partner Integration
                    </Link>
                  </li>
                  <li>
                    <Link to="/service/process-scaling">Process Scaling</Link>
                  </li>
                  <li>
                    <Link to="/service/cloud-migration">Cloud Migration</Link>
                  </li>
                </ul>
              </div> */}

              <div className="nav-block">
                <h3>
                  <Message dictKey="footer.contact.us" />
                </h3>
                <ul>
                  <li>
                    <a
                      className={`contact-link phone-india ${
                        isDirectionRTL && "contact-link-rtl phone-india-rtl"
                      }`}
                      href="tel:+918041126521"
                    >
                      +91 80411 26521
                    </a>
                  </li>
                  <li>
                    <a
                      className={`contact-link phone-uk ${
                        isDirectionRTL && "contact-link-rtl phone-uk-rtl"
                      }`}
                      href="tel:+442382029871"
                    >
                      +44 23820 29871
                    </a>
                  </li>
                  <li>
                    <a
                      className={`contact-link phone-us ${
                        isDirectionRTL && "contact-link-rtl phone-us-rtl"
                      }`}
                      href="tel:+18582076278"
                    >
                      +1 858 207 6278
                    </a>
                  </li>
                  <li>
                    <a
                      className={`contact-link email-link ${
                        isDirectionRTL && "contact-link-rtl email-link-rtl"
                      }`}
                      href="mailto:info@zopsmart.com"
                    >
                      info@zopsmart.com
                    </a>
                  </li>
                </ul>
              </div>
              <div className="nav-block">
                <h3>
                  <Message dictKey="footer.stay.in.touch" />
                </h3>
                <ul className="social-links">
                  <li>
                    <a
                      className="facebook-link"
                      href="https://www.facebook.com/ZopSmart/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Message dictKey="footer.facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="linkedin-link"
                      href="https://www.linkedin.com/company/zopsmart/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Message dictKey="footer.linkedIn" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="twitter-link"
                      href="https://twitter.com/zopsmart"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Message dictKey="footer.twitter" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-info">
              <img
                className="footer-logo"
                src={logo}
                alt="footer-logo"
                loading="lazy"
              />
              <div className="copyright">
                &copy;
                <Message dictKey="footer.raramuri" /> {new Date().getFullYear()}
                . <Message dictKey="footer.rights" />
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
