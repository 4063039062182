import React from 'react'
import LanguageProvider, {
  LanguageContext,
} from '../../Providers/LanguageProvider'

export default function WithProviders(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <LanguageProvider language={this.props.pageContext.language}>
          <LanguageContext.Consumer>
            {(languageContextProps) => (
              <WrappedComponent {...this.props} {...languageContextProps} />
            )}
          </LanguageContext.Consumer>
        </LanguageProvider>
      )
    }
  }
}
