import React, { useEffect, useState } from "react";
import supportedLang from "../../lib/supportedLanguages";

const RTLLanguage = ["ar"];
const LanguageContext = React.createContext({
  updateLanguage: () => {},
  language: "en",
  dictionary: {},
  direction: "ltr",
});

const LanguageProvider = (props) => {
  const [language, setLanguage] = useState(props.language || "");
  const [direction, setDirection] = useState(
    RTLLanguage.includes(props.language) ? "rtl" : "ltr",
  );

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  const updateLanguage = (lang) => {
    setLanguage(lang);
    const path = window.location.pathname;
    const searchParams = window.location.search;

    let pathComponents = path.split("/");

    if (supportedLang.indexOf(pathComponents[1]) > -1) {
      // replace the old lang with newly selected lang
      pathComponents.splice(1, 1, lang);
    } else {
      // prefix the lang to the url
      pathComponents.splice(1, 0, lang);
    }

    const updatedPath = pathComponents.join("/");
    // updated url with selected language
    window.location.href = updatedPath + searchParams;
  };
  const dictionaryKeys = require(`../../languages/${language || "en"}`);
  return (
    <LanguageContext.Provider
      value={{
        updateLanguage,
        language,
        dictionary: dictionaryKeys.default,
        direction,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;

export { LanguageContext };
